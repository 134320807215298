import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CloudVisionService {

  LABEL_DETECTION = 'LABEL_DETECTION';
  TEXT_DETECTION = 'TEXT_DETECTION';
  DOCUMENT_TEXT_DETECTION = 'DOCUMENT_TEXT_DETECTION';

  lang_table = {
    'es': 'US',
    'ja': 'JP',
    'fr': 'FR',

  };

  constructor(public http: Http) { }

  getLabels(base64Image, type, limit) {
    const body = {
      "requests": [
        {
          "image": {
            "content": base64Image
          },
          "features": [
            {
              "type": type,
              "maxResults": limit
            }
          ]
        }
      ]
    }
    return this.http.post('https://vision.googleapis.com/v1/images:annotate?key=' + environment.cloud_vision_key, body);
  }

  getTranslation(phrase, source, target) {
    const body = {
      'q': phrase,
      'source': source,
      'target': target
    };

    return this.http.post('https://www.googleapis.com/language/translate/v2?key='+ environment.cloud_vision_key, body);
  }

  getVoiceAudio(phrase, lang) {
    const body = {
      'input':{
        'text': phrase
      },
      'voice':{
        'languageCode':lang + '-' + this.lang_table[lang],
        'ssmlGender':'FEMALE'
      },
      'audioConfig':{
        'audioEncoding':'MP3'
      }
    };

    return this.http.post('https://texttospeech.googleapis.com/v1/text:synthesize?key='+ environment.cloud_vision_key, body);
  }
}
